import React, { useState } from 'react';
import * as styles from './EmailListForm.module.scss';
import addToMailchimp from 'gatsby-plugin-mailchimp';

const EmailListForm: React.FunctionComponent<{}> = () => {

  const [email, setEmail] = useState('');



    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    addToMailchimp(email)
      .then((data) => {
        alert(data.result);
      })
      .catch((error: Error) => {
        // Errors in here are client side
        // Mailchimp always returns a 200
      });
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };

  return (

    <form onSubmit={handleSubmit} className={styles.EmailListForm}>
      <strong>   I did away with ads because they work neither for me nor my readers. However, if you like my work and want to keep me motivated to write more. Consider</strong>
      <div className={styles.Wrapper}>
	<a href="https://www.buymeacoffee.com/thedatabus" class="coffee-button">BUYING ME A COFFEE</a>
      </div>
    </form>

  );
};

export default EmailListForm;
