import React, {CSSProperties, FunctionComponent} from "react";
import styled from "styled-components";
import {graphql, useStaticQuery} from "gatsby";
import {SiteMetadata} from "../../utils/models";
import SocialChannelList from "../social-channel-list";
import Avatar from "../avatar";
import EmailListForm from '../EmailListForm/EmailListForm';
/*import { Formik, Form, Field, ErrorMessage } from 'formik';
import Contactform from '../contactform/contactform';
import DarkModeToggle from '../darkmode/DarkModeToggle';*/

interface BioProps {
  textAlign: 'left' | 'center' | 'right' | 'justify';
  avatarStyle?: CSSProperties;
  showName?: boolean;
}

const StyledBio = styled.section<Pick<BioProps, 'textAlign'>>`
  margin: auto;
  text-align: ${props => props.textAlign};
  width: 100%;
`;

const AuthorDescription = styled.p`
  margin: 10px 0 13px;

  a {
    color: #000;
    text-decoration: underline;
  }
`;

const AuthorName = styled.h3`
  margin: 10px;
`;

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const Bio: FunctionComponent<BioProps> = ({textAlign = 'center', avatarStyle, showName = false}) => {
  const metadata = useStaticQuery<SiteMetadata>(graphql`
    query MetaQuery {
      site {
        siteMetadata {
          author {
            name
            description
            social {
              facebook
              twitter
              linkedin
              instagram
              youtube
              github
              twitch
              reddit
              email
            }
          }
        }
      }
    }
  `);

  const author = metadata.site.siteMetadata.author;

  return (
    <StyledBio textAlign={textAlign}>
      <Avatar alt={author.name} style={avatarStyle} />
      {showName && <AuthorName>{author.name}</AuthorName>}
      <AuthorDescription dangerouslySetInnerHTML={{__html: author.description}}/>
      <SocialChannelList channels={author.social}/>
      <EmailListForm></EmailListForm>
    </StyledBio>
  );
};

export default Bio;
